import { useAuthorization } from '~/composables/auth/useAuthorization';
import { useAuthStore } from '~/stores/auth/useAuthStore';

/** Middleware проверяет авторизацию. */
export default defineNuxtRouteMiddleware(async () => {
    // токен на стороне клиента, поэтому серверную обработку пропускаем
    if(import.meta.server) {
        return;
    }
    
    const store = useAuthStore();
    
    if(!store.isAuthorized) {
        const { redirectToLogoutUrl } = useAuthorization();
        redirectToLogoutUrl();
    }
})